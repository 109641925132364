import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export function LicaInput({ licaError }) {

    return (
        <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
                margin="normal"
                fullWidth
                error={licaError}
                helperText={licaError}
                id="licaKey"
                label="Lica CRM Token"
                name="licaKey"
                autoComplete="crm-key"
            />
        </FormControl>
    )
}
