import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import '../../assets/basic.css'
import { NewValueForm } from './NewValueForm';
import { updateConstantByName } from '../../api/server/updateConstantByName';
import { createConstant } from '../../api/server/createConstant';

const style = {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: '600px',
    maxWidth: '100vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function ConstantForm({ data, open, handleClose, setOpen, rows }) {
    const [constant, setConstant] = useState(data);
    const [newValueForm, setNewValueForm] = useState('')
    const [error, setError] = useState(false)
    const [aliasError, setAliasError] = useState(false)

    useEffect(() => {
        setConstant(data)
        setNewValueForm('')
    }, [data])

    const handleAliasChange = (event) => {
        const value = event.target.value;

        const regex = /^[A-Za-z0-9]+(\.[A-Za-z0-9]+)$/;
        setAliasError(!regex.test(value));
        
        setConstant({ ...constant, [event.target.name]: event.target.value })
    };

    const handleBlur = () => {
        const title = constant.alias.split('.')[0];
        const match = rows.find( el => el.title === title)
        if(match){
            setError(true)
            return
        }
        if(!aliasError) {
            setError(false)
            setConstant({ ...constant, 'title': title })
        }
      };

    const handleChangeValue = (event) => {
        setConstant({ ...constant, value: { ...constant.value, [event.target.name]: event.target.value } })
    };

    const handleAddValue = () => {
        setNewValueForm(<NewValueForm constant={constant} setConstant={setConstant} setNewValueForm={setNewValueForm} />)
    };


    const handleDeleteValue = (key) => {
        const result = delete constant.value[key]
        if (result) setConstant({ ...constant, value: constant.value })
    }

    const updateConstant = async (name, value) => {
        if (name && value) {
            if(constant?.isNew) {
                delete value.isNew; 
                await createConstant(value);
                setOpen(false)
                return;
            }
            await updateConstantByName(name, value);
            setOpen(false)

        }
    }

    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <FormControl fullWidth >
                    <TextField
                        label="Title"
                        name='title'
                        id="constant-name"
                        value={constant?.title ?? ''}
                        size="small"
                        sx={{ mb: 2 }}
                        error={error}
                        disabled={true}
                    />
                    <TextField
                        label="Alias"
                        name='alias'
                        id="constant-alias"
                        value={constant?.alias ?? ''}
                        onChange={handleAliasChange}
                        onBlur={handleBlur}
                        size="small"
                        autoFocus={true}
                        error={aliasError}
                        sx={{ mb: 2 }}
                        disabled={!!constant?.used?.length}
                    />
                    <p>Values:</p>
                    {constant?.value ? Object.keys(constant.value).map((el, index) => {
                        return (
                            <div key={index}>
                                <TextField
                                    label="Key"
                                    id="constant-alias"
                                    value={el}
                                    size="small"
                                    sx={{ m: 1, width: '25%' }}
                                    disabled
                                />
                                <TextField
                                    label="Value"
                                    name={el}
                                    data-type="value"
                                    id="constant-alias"
                                    value={constant.value[el]}
                                    onChange={handleChangeValue}
                                    size="small"
                                    sx={{ m: 1, width: '60%' }}
                                />
                                <IconButton onClick={() => handleDeleteValue(el)} title='Delete key' sx={{ mt: 1, width: '6%', "&:hover": { background: 'none !important' } }}>
                                    <CloseIcon sx={{ color: '#d48181' }} />
                                </IconButton>
                            </div>
                        )
                    }) : ''}
                    {newValueForm}
                    <IconButton title='add value' onClick={handleAddValue} sx={{ width: 30, "&:hover": { background: 'none !important', opacity: 0.8 } }}>
                        <AddCircleIcon />
                    </IconButton>
                    <Box sx={{ textAlign: 'right', m: 1 }}>
                        <Button variant="outlined" sx={{ mr: 2 }} onClick={handleClose} >Close</Button>
                        <Button variant="contained" onClick={() => constant?.title ? updateConstant(constant.title, constant) : setError(true)} disabled={error || aliasError}>
                            {constant?.isNew ? 'Create' : 'Save'}
                        </Button>
                    </Box>
                </FormControl>
            </Box>
        </Modal>
    )
}
