export default async function validateHtml(variables, constants, html) {
    const pattern = /{{(.*?)}}/g;
    const matches = html.match(pattern);
    if (matches) {
        const withoutBraces = matches.map(match => match.slice(2, -2).trim());
        const detectedVariables = withoutBraces.filter(el => !el.includes('.'))
        if (detectedVariables.length) {
            const newArray = [...new Set(detectedVariables.filter(element => !variables.find(el => el === element)))];
            if (newArray.filter(el => el.length).length) return `Detected not recorded variables: ${newArray.join(' ')}`
        }

        const detectedConstants = withoutBraces.filter(el => el.includes('.'))
        if (detectedConstants.length) {
            const titlesOfDetectedConstants = detectedConstants.map(el => el.split('.')[0]);
            const newArray = [...new Set(titlesOfDetectedConstants.filter(element => !constants.find(el => el === element)))];
            if (newArray.filter(el => el.length).length) return `Detected not recorded constants: ${newArray.join(' ')}`
        }
        return true

    } else {
        //no variables in content
        return true
    }

}