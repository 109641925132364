import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

export function VariablesInput(props) {
    const { value, setValue } = props;
    const [variableString, setVariableString] = useState(value.join(' '))
    
    useEffect(() => {
        setVariableString(value.join(' '))
    }, [value])

    const convertToArray = () => {
        if (variableString.length) setValue( prewValue => { return {...prewValue, variables: variableString.split(' ') }});
    }
    
    const handleChange = (e) => {
        setVariableString(e.target.value);
    };

    return (
        <FormControl fullWidth >
            <TextField
                label="Variables"
                name='variables'
                value={variableString}
                onChange={handleChange}
                onBlur={convertToArray}
                size="small"
            />
        </FormControl>
    )
}


