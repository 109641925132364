import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { CreateNewTemplateForm } from './CreateNewTemplateForm';
import { getHtmlTemplates } from "../../api/server/getHtmlTemplates"
import { deleteTemplate } from "../../api/server/deleteTemplate"

const columns = [
    { id: 'title', label: 'Title', minWidth: 370 },
    { id: 'crm', label: 'Crm', minWidth: 100 },
    { id: 'lastUpdate', label: 'Last update', minWidth: 100 },

];

export function Templates() {
    const [data, setData] = useState()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const handleRowClick = (event, title) => {
        if (event.target.closest('button')) {
            // Click was on the button or its child elements, so ignore the row click
            return;
        }

        navigate(`/templates/${title}`);
    };

    const handleDelete = async (title) => {
        const newTemplatesList = await deleteTemplate(title)
        setData(newTemplatesList)

    }

    useEffect(() => {
        async function fetchData() {
            const templates = await getHtmlTemplates();
            setData(templates)
        }
        fetchData();
    }, [])


    return (
        <>
            <Typography variant="h5" align="center" sx={{ pb: 3 }}>
                HTML email templates
            </Typography>
            <Button title='add new template ' sx={{ position: 'absolute', top: 70, right: 20, m: 1, background: '#f1f1f1', border: '1px solid #e1e1e1' }} onClick={handleOpen}>
                <AddIcon />
            </Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleOpen}
            >
                <>
                    <CreateNewTemplateForm handleOpen={handleOpen} data={data} setData={setData} />
                </>
            </Modal>
            <Box
                sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper', border: '1px solid #efefef', p: 0 }}
                aria-label="contacts"
            >
                <Table stickyHeader aria-label="sticky table" sx={{ maxHeight: '90vh', border: 'none' }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: '#e3e3e3', fontWeight: 'bold', fontSize: 17 }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} name={row.title} key={index} onClick={e => handleRowClick(e, (row.title))} sx={{ cursor: 'pointer' }}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={`delete-${index}`} sx={{ width: 40, p: 0, textAlign: 'center', zIndex: 100 }}>
                                        <IconButton title='Delete' onClick={() => handleDelete(row.title)} sx={{ width: 10, "&:hover": { background: 'none !important', opacity: 0.8 } }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </Box>
        </>
    );
}
