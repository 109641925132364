import React from 'react'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export function CrmInput(props) {
    const allAvailableCrm = ['', 'melissa', 'lica']
    const { value, setValue } = props;

    const handleChange = (e) => {
        setValue( prewValue => { return {...prewValue, crm: e.target.value}});
    };

    return (
        <FormControl sx={{ mt: 2, mb: 2 }} size="small" >
            <InputLabel id="demo-select-small-label">crm</InputLabel>
            <Select value={value} label="Crm" onChange={handleChange} >
                {allAvailableCrm.map((el, index) => <MenuItem key={index} value={el}>{el}</MenuItem>)}
            </Select>
        </FormControl>
    )
}
