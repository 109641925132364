import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { getConstants } from '../../api/server/getConstants'

export function ConstantsInput(props) {
    const [allConstants, setAllConstants] = useState([]);
    const { value, setValue } = props;

    useEffect(() => {
        async function fetchData() {
            const constantsList = await getConstants();
            const data = constantsList.map(el => el.title)
            setAllConstants(data)
        }
        fetchData();

    }, [])

    const handleChange = (e) => {
        setValue( prewValue => { return {...prewValue, constants: e.target.value}});
    };

    return (
        <FormControl fullWidth>
            <InputLabel sx={{ top: 10, background: '#fff', p: 1 }}>Constants</InputLabel>
            <Select multiple value={value} onChange={handleChange} renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: 0.5, p: 0, m: 0 }}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} />
                    ))}
                </Box>
            )} sx={{ mt: 2, mb: 2 }}>
                {allConstants?.map((name, index) => (
                    <MenuItem
                        key={index}
                        value={name}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
