import { getLicaTemplateTranslationIds } from "../../api/lica/getLicaTemplateTranslationIds";


export default async function getLicaRequest(templateId, language, brandId, triggerId, subject, html, licaToken, setError) {
  
  const templateInfo = await getLicaTemplateTranslationIds(templateId, licaToken);
    if(templateInfo.brand != brandId) {
      setError(`wrong brand id in ${templateId} ${language}`)
      return
    }
    if(templateInfo.trigger_id != triggerId) {
      setError(`wrong trigger id id in ${templateId} ${language}`)
      return
    }
    
    if(templateInfo === '500 - HTTP_INTERNAL_SERVER_ERROR') {
      setError('Update LICA API Key')
      return
    }
    const id = templateInfo.translations.find( id => id.code === language)?.id

    if(!id) {
        setError(`translation_id not found for ${templateId} ${language} translation`);    
      }

      
    return {
        "id": id,
        "template_id": templateId,
        "code": language,
        "subject": subject,
        "text": html
      }

}