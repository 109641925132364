import { home } from '../../constants/homeUrl'

export async function createTemplate(template) {
    const url = `${home}/api/templates`;
    const data = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(template)
    }).catch(error => {
        console.error(error)
        return error
    })
    if(data.status === 200) {
        return data.json()
    }
    return `${data.status} - ${data.message}`
}