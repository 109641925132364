import React from 'react'
import Typography from '@mui/material/Typography';

export function WelcomeBlock() {
    

    return (
        <>
      <Typography variant="h4" gutterBottom>
      Welcome to the party 🎉
      </Typography>
            
        </>
    )
}
