import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CodeEditor from '@uiw/react-textarea-code-editor';

export function HtmlInput(props) {
    const { value, setValue, maxHeight } = props;
    

    const handleChange = (e) => {
        setValue(prewValue => { return { ...prewValue, html: e.target.value } });
    };

    return (
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{ top: -35, fontSize: 14 }}>html</InputLabel>
            <CodeEditor label="html" value={value} id="html" language="html" onChange={handleChange}
                padding={10}
                style={{
                    fontSize: 12,
                    minHeight: '15em',
                    maxHeight: maxHeight,
                    overflowY: 'scroll',
                    backgroundColor: "#fefefe",
                    border: "1px solid #e4e4e4",
                    fontFamily:
                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace"
                }} />
        </FormControl>
    )
}
