export const langID = {
    ru: '1',
    en: '2',
    de: '3',
    pl: '4',
    tr: '5',
    uk: '6',
    es: '7',
    pt: '8',
    fi: '9',
    no: '10',
    sv: '11',
    nl: '12',
    fr: '13',
    ja: '14',
    uz: '15',
    kk: '16',
    hi: '17',
    cz: '18'
}