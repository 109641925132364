import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import { Preview } from "../../components/Preview/Preview";
import { getHtmlTemplateByName } from '../../api/server/getHtmlTemplateByName'
import { updateTemplateByName } from '../../api/server/updateTemplateByName'

import { VariablesInput } from '../../components/Inputs/VariablesInput';
import { ConstantsInput } from '../../components/Inputs/ConstantsInput';
import { HtmlInput } from '../../components/Inputs/HtmlInput';
import { emptyTemplate } from '../../constants/emptyTemplate'
import '../../assets/basic.css'
import validateHtml from './validateHtml';


const TemplatePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const templateName = location.pathname.split('/').pop();
  const [error, setError] = useState(true);
  const [open, setOpen] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [data, setData] = useState(emptyTemplate);
  const [htmlFromServer, setHtmlFromServer] = useState('');

  const handleOpen = () => setOpen(true);
  const handleSave = async () => {
    const validated = await validateHtml(data.variables, data.constants, data.html)
    if(!validated || validated.length) {
      setError(validated)
      setSaveButtonDisabled(true)
      return
    }
    setError(true)
    setSaveButtonDisabled(false)
    const requestData = {
      crm: data.crm,
      variables: data.variables,
      constants: data.constants,
      html: data.html ?? ''
    }
    await updateTemplateByName(data.title, requestData);
    setSaveButtonDisabled(true)
  };
  const validateHTML = (html) => {
    if (html !== htmlFromServer) {
      return true
    }
  }

  useEffect(() => {
    async function fetchTemplate() {
      const info = await getHtmlTemplateByName(templateName);
      const constantTitles = info.constants.map(el => el.title)
      setHtmlFromServer(info.html)
      setData({ ...info, constants: constantTitles })
    }
    fetchTemplate();

  }, [templateName])

  useEffect(() => {
    if (data.html.length) {
      if (validateHTML(data.html)) { }
    }
    setSaveButtonDisabled(false)
  }, [data])

  return (
    <Box>
      <Button variant="outlined" onClick={() => navigate(-1)} >Back</Button>

      <Typography variant="h5" align="center" sx={{ mb: 1 }}>
        {templateName}
      </Typography>

      <IconButton className='show-preview-button' title='Preview' onClick={handleOpen} sx={{ background: 'white' }}>
        <PreviewIcon />
      </IconButton>
      <Modal
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal">
          <Preview key={1} index={1} language={''} templateId={'--'} subject={'---'} templateHTML={data.html} markUpOnly={true} />
        </Box>
      </Modal>

      <Paper elevation={2} sx={{ maxWidth: '90vw', width: '100%', m: '0 auto', p: 2 }}>
        <Box>
          <VariablesInput value={data.variables} setValue={setData} />
          <ConstantsInput value={data.constants} setValue={setData} />
          <HtmlInput value={data.html} setValue={setData} />

          <Button onClick={handleSave} variant="contained" sx={{ mt: 2 }} disabled={saveButtonDisabled}>
            Save
          </Button>
          <Typography variant="text" align="center" sx={{ textAlign: 'right', display: 'inline-block', verticalAlign: 'bottom', lineHeight: 2, color: 'red', ml: 2 }}>
            {typeof error === 'string' ? error : ''}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default TemplatePage;