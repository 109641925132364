import { home } from '../../constants/homeUrl'

export async function updateConstantByName(name, value) {
    const url = `${home}/api/constants/${name}`;

    const data = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(value)
    }).then(async (data) => await data.json()).catch(error => {
        console.error(error)
        return error
    })
    return data
}