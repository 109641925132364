import React, {useState} from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';

export function NewValueForm({constant, setConstant, setNewValueForm}) {
    const [newConstant, setNewConstant] = useState({newKey: '', newValue: ''})
    
    
    const createValue = (key, value) => {
        setConstant({ ...constant, value: { ...constant.value, [key]: value } })
        setNewConstant({newKey: '', newValue: ''})
        setNewValueForm('')
    }

    const handleInputNewConstant = (name, value) => {
        setNewConstant({ ...newConstant, [name]: value })
    }

    return ( 
    <Box sx={{mt:1}}>
        <TextField
            label="Key"
            value={newConstant?.key}
            onChange={e => handleInputNewConstant('newKey', e.target.value)}
            size="small"
            sx={{ ml: 1, width: '25%'}}
        />
        <TextField
            label="Value"
            value={newConstant?.value}
            onChange={e => handleInputNewConstant('newValue', e.target.value)}
            size="small"
            sx={{ ml: 2, mr: 1, width: '60%' }}
        />
        <IconButton onClick={ () => createValue(newConstant.newKey, newConstant.newValue)} title='add' sx={{ width: '6%', "&:hover": { background: 'none !important'} }}>
            <DoneIcon sx={{ color: '#52c752'}} />
        </IconButton>
    </Box>
    )
}
