import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import { createTemplate } from '../../api/server/createTemplate';
import { VariablesInput } from '../../components/Inputs/VariablesInput';
import { ConstantsInput } from '../../components/Inputs/ConstantsInput';
import { CrmInput } from '../../components/Inputs/CrmInput';
import { HtmlInput } from '../../components/Inputs/HtmlInput';

const ValidationTextField = styled(TextField)({
    '& input:valid + fieldset': {
        borderColor: '#E0E3E7',
        borderWidth: 1,
    },
    '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
        borderLeftWidth: 4,
        padding: '4px !important',
    },
});

export function CreateNewTemplateForm({ handleOpen, data, setData }) {
    const emptyTemplate = {
        title: '',
        crm: [],
        constants: [],
        variables: [],
        html: ''
    }
    const [error, setError] = useState(true);
    const [newTemplate, setNewTemplate] = useState(emptyTemplate)

    const validateTitle = (event) => {
        const newTitle = event.target.value
        setNewTemplate({ ...newTemplate, title: newTitle });
        if (!newTitle.length) {
            setError('Title is empty')
            return
        }

        const pattern = /^[A-Za-z0-9]+$/;
        if (!pattern.test(newTitle)) {
            setError('Title must contain only latin letters or numbers (a-z, A-Z, 0-9)');
            return

        }

        const existedTemplateTitles = data.map(el => el.title)
        const template = existedTemplateTitles.find(el => el === newTitle)
        if (template) {
            setError('Template with this title is already exist');
            return
        }

        setError('')
    }

    const handleCreate = async () => {
        const result = await createTemplate(newTemplate)
        if (typeof result === 'string') {
            setError(result)
            return
        }
        setError('')
        setData(result)
        setNewTemplate(emptyTemplate)
        handleOpen()
    };

    return (
        <Box className="modal" sx={{ p: 2, minWidth: '40%' }}>
            <FormControl fullWidth >
                <Typography variant="h6" align="center" sx={{ pb: 2 }}>
                    Create template
                </Typography>

                <ValidationTextField
                    label="Title"
                    name='title'
                    value={newTemplate?.title}
                    onChange={validateTitle}
                    size="small"
                    variant="outlined"
                    required
                />
                <CrmInput value={newTemplate.crm} setValue={setNewTemplate} />
                <VariablesInput value={newTemplate.variables} setValue={setNewTemplate} />
                <ConstantsInput value={newTemplate.constants} setValue={setNewTemplate} />
                <HtmlInput value={newTemplate.html} setValue={setNewTemplate} maxHeight={1} /> 

                <Typography variant="text" align="center" sx={{ textAlign: 'left', color: 'red', m: 1 }}>
                    {typeof error === 'string' ? error : ''}
                </Typography>
                <Box sx={{ textAlign: 'right', mt: 2, mb: 1 }}>
                    <Button variant="outlined" sx={{ mr: 2 }} onClick={handleOpen} >Close</Button>
                    <Button variant="contained" onClick={handleCreate} disabled={!!error} >
                        Create
                    </Button>
                </Box>
            </FormControl>
        </Box>
    )
}
